<template>
  <div v-if="!portalMode">
    <InvestorsMobileNav />
  </div>

  <div v-if="portalMode == 'esop'">
    <EsopMobileNav />
  </div>

  <div v-if="portalMode == 'founder'">
    <FounderMobileNav />
  </div>
</template>

<script>
import EsopMobileNav from "@/Shared/Components/Nav/EsopMobileNav.vue";
import FounderMobileNav from "@/Shared/Components/Nav/FounderMobileNav.vue";
import InvestorsMobileNav from "@/Shared/Components/Nav/InvestorsMobileNav.vue";

export default {
  components: {
    InvestorsMobileNav,
    EsopMobileNav,
    FounderMobileNav
  },
  props: {
    portalMode: {
      type: String,
      default: null,
    },
  },
};
</script>