<template>
  <div v-if="!portalMode">
    <InvestorsNav />
    <TopMobileNav />
  </div>

  <div v-if="portalMode == 'esop'">
    <EsopNav />
    <EsopTopMobileNav />
  </div>

  <div v-if="portalMode == 'founder'">
    <FounderNav />
    <FounderTopMobileNav />
  </div>

  <div v-if="portalMode == 'fund-operator'">
    <FundOperatorNav />
  </div>
</template>

<script>
import FounderTopMobileNav from "@/Shared/Components/Nav/FounderTopMobileNav.vue";
import EsopTopMobileNav from "@/Shared/Components/Nav/EsopTopMobileNav.vue";
import InvestorsNav from "@/Shared/Components/Nav/InvestorsNav.vue";
import TopMobileNav from "@/Shared/Components/Nav/TopMobileNav.vue";
import FounderNav from "@/Shared/Components/Nav/FounderNav.vue";
import EsopNav from "@/Shared/Components/Nav/EsopNav.vue";
import FundOperatorNav from "@/Shared/Components/Nav/FundOperatorNav.vue";

export default {
  components: {
    FundOperatorNav,
    TopMobileNav,
    InvestorsNav,
    EsopNav,
    EsopTopMobileNav,
    FounderNav,
    FounderTopMobileNav,
  },
  props: {
    portalMode: {
      type: String,
      default: null,
    },
  },
};
</script>