<template>
  <div
    class="min-h-screen lg:flex overflow-hidden pb-20 mb-2 lg:pb-0 antialiased bg-black"
  >
    <SideBar :portal-mode="portalMode" />

    <div class="flex flex-col flex-1 overflow-hidden">
      <TopBar :section-links="sectionLinks">
        <template #top-bar>
          <slot name="top-bar" />
        </template>
      </TopBar>
      
      <main class="bg-white h-full rounded-3xl mx-0 md:mx-4 shadow-2xl drop-shadow-xl z-10 pb-36 ">
        <slot name="before-nav" />

        <div :class="layoutClasses">
          <div class="">
            <INotification
              v-if="$page.props.flash.message"
              :type="$page.props.flash.type"
              :content="$page.props.flash.message"
            >
              {{ $page.props.flash.message }}
            </INotification>
          </div>

          <slot name="after-nav" />

          <slot />
        </div>
      </main>

      <slot name="after-content" />
    </div>

    <MobileNav :portal-mode="portalMode" />
  </div>
</template>

<script>
import INotification from "@/Shared/Components/Notification/INotification.vue";
import * as Sentry from "@sentry/browser";
import SideBar from "@/Shared/Components/Nav/SideBar.vue";
import TopBar from "@/Shared/Components/Nav/TopBar.vue";
import MobileNav from "@/Shared/Components/Nav/MobileNav.vue";

export default {
  components: {
    TopBar,
    SideBar,
    MobileNav,
    INotification,
  },
  props: {
    sectionLinks: Array,
    layoutClasses: {
      type: String,
      default: "max-w-7xl mx-auto px-4 pb-6 lg:px-10",
    },
    portalMode: String,
  },
  mounted() {
    if (this.$page.props.user && !this.$page.props.user.is_impersonating) {
      Sentry.setUser({
        id: this.$page.props.user.id,
        email: this.$page.props.user.email,
        username: this.$page.props.user.name,
      });
      this.$posthog.identify(
        this.$page.props.user.id,
        {
          email: this.$page.props.user.email,
          name: this.$page.props.user.name,
        }
      );
    }
  },

};
</script>
