<template>
  <div class="my-3 mx-0 md:mx-4 bg-transparent md:bg-zinc-900 rounded-full pl-0 xl:pl-3">
    <Menubar
      v-if="$page.props.user"
      class="min-h-10 md:min-h-20 block xl:flex items-center pt-0 md:pt-5 xl:pt-0"
    >
      <template #start>
        <slot name="top-bar" />

        <SectionNav
          v-if="sectionLinks?.length > 0"
          :links="sectionLinks"
        />
      </template>

      <template
        v-if="!route().current('investors.investment.*')"
        #end
      >
        <div class="relative hidden lg:flex space-x-4">
          <div class="hidden xl:flex">
            <EntitiesSelection
              v-if="$page.props.user?.raw_entities.length > 1"
            />
          </div>

          <div class="mt-2 pr-10">
            <button
              class="cursor-pointer text-white"
              aria-haspopup="true"
              aria-controls="overlay_menu"
              @click="toggleMenu"
            >
              <i class="fa-regular fa-gear" />
            </button>

            <PrimeMenu
              id="overlay_menu"
              ref="menu"
              :model="items"
              :popup="true"
              :show-transition-options="menuOptions"
              class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50"
            >
              <template #start>
                <span class="inline-flex items-center p-2 ml-3 text-sm uppercase text-zinc-400">
                  <span class="">{{ $page.props.user.name }}</span>
                </span>
              </template>

              <template #item="{ item, props }">
                <Link
                  class="flex items-center"
                  :href="item.route"
                  v-bind="props.action"
                >
                  <i :class="props.icon.class" />
                  <span class="ml-2 text-sm">{{ item.label }}</span>
                </Link>
              </template>
            </PrimeMenu>
          </div>
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script>

import Menubar from "primevue/menubar";
import PrimeMenu from "primevue/menu";
import SectionNav from "@/Shared/Components/Nav/SectionNav.vue";
import EntitiesSelection from "@/Shared/Components/Elements/EntitiesSelection.vue";

export default {
  components: {
    EntitiesSelection,
    SectionNav,
    Menubar,
    PrimeMenu
  },
  props: {
    sectionLinks: Array,
  },
  data() {
    return {
      displayMenu: true,
      items: [
        {
          label: "Settings",
          icon: "fa-regular fa-gear",
          route: route("investors.settings")
        },
        {
          label: "Sign Out",
          icon: "fa-solid fa-arrow-right-to-bracket",
          route: route("logout"),
        }
      ],
      menuOptions: "opacity: 0.75"
    };
  },
  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    }
  },
};
</script>