<template>
  <nav class="min-h-screen hidden lg:flex lg:shrink-0">
    <div
      class="h-full flex flex-col w-64"
    >
      <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto fixed w-64">
        <div class="flex items-center shrink-0 my-4">
          <Link
            :href="route('investors.home')"
            class="pl-6"
          >
            <img
              src="/images/logo-large-white.svg"
              alt="Icehouse Ventures Logo"
              class="w-44"
            >
          </Link>
        </div>

        <nav
          v-if="$page.props.user"
          class="flex-1 mt-10"
        >
          <Link
            :href="route('raise.company')"
            :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('raise.company') }"
            class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
          >
            <i class="fa-solid fa-house mr-3" /> Dashboard
          </Link>

          <Link
            :href="route('raise.offer.index' )"
            :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('raise.offer.*') }"
            class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
          >
            <i class="fas fa-hand-holding-usd mr-3" /> Raise Capital 
          </Link>


          <Link
            :href="route('company.metrics.index')"
            :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('company.metrics.index') }"
            class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
          >
            <i class="fa-solid fa-chart-line-up-down mr-3" /> Metrics
          </Link>

          <Link
            :href="route('company.benchmarks.charts')"
            :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('company.benchmarks.charts') }"
            class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
          >
            <i class="fas fa-analytics mr-3" /> Benchmarks
          </Link>

          <Link
            :href="route('registry.index')"
            :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('registry.*') }"
            class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
          >
            <i class="fa-solid fa-chart-pie mr-3" /> Cap Table
          </Link>

          <Link
            :href="route('esop.management.overview')"
            :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('esop.management.*') }"
            class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
          >
            <i class="fa-solid fa-users mr-3" /> ESOP Management
          </Link>

          <hr class="w-52 my-2 h-[0.5px] mx-auto border-0 bg-white bg-opacity-[0.2] mb-5">
          <Link
            :href="route('investors.home')"
            :class="{ 'font-medium border-white': route().current('investors.home') }"
            class="group flex items-center justify-center text-center mx-4 text-sm my-1 text-zinc-400 border border-zinc-400 rounded-full focus:outline-none hover:text-white hover:border-white p-3 transition-all ease-in-out"
          >
            My Investments
          </Link>

          <div v-if="$page.props.user?.is_admin">
            <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2] mb-5">

            <a
              href="/admin"
              :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.*') }"
              class="group flex items-center justify-center text-center mx-4 text-sm my-1 text-zinc-400 border border-zinc-400 rounded-full focus:outline-none hover:text-white hover:border-white p-3 transition-all ease-in-out"
            >
              Staff Portal
            </a>
          </div>

          <div v-if="$page.props.user.is_esop_participant">
            <hr class="w-52 my-2 h-[0.5px] mx-auto border-0 bg-white bg-opacity-[0.2] mb-5">
            <Link
              :href="route('esop.home')"
              :class="{ 'font-medium border-white': route().current('investors.settings') }"
              class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-lg leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
            >
              Equity
            </Link>
          </div>

          <div v-if="$page.props.user.has_fund_operator">
            <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2] mb-5">

            <Link
              :href="route('operator.overview')"
              :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.*') }"
              class="group flex items-center justify-center text-center mx-4 text-sm my-1 text-zinc-400 border border-zinc-400 rounded-full focus:outline-none hover:text-white hover:border-white p-3 transition-all ease-in-out"
            >
              Manage Fund
            </Link>
          </div>
        </nav>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  data: () => ({
    userMenuOpen: false,
    mobileNavOpen: false,
  }),
};
</script>
